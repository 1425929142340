@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Montserrat-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: normal;
  src: url('fonts/Montserrat-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Montserrat-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/Montserrat-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Montserrat-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/Montserrat-SemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('fonts/Montserrat-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: bold;
  src: url('fonts/Montserrat-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/Montserrat-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/Montserrat-ExtraBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/Montserrat-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/Montserrat-BlackItalic.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
