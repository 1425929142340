:root {
  --bs-gutter-x: 29px;
  --bs-gutter-y: 18px;
  --mindaro-theme-primary-background: #0A0909;
	--mindaro-theme-primary: #27AE60;
	--mindaro-theme-secondary: #8836B4;
	--mindaro-theme-third: #FB772E;
	--mindaro-theme-primary-text: #F8F8F8;
  --mindaro-pink: #FF01B8;
  --mindaro-blue: #428bf9;
  --mindaro-yellow: #ffd812;
  --mindaro-green: #27ae60;
  --mindaro-orange: #FB842A;
  --mindaro-grey: #272626;
  ;
  --hover-color: var(--mindaro-pink);
  --form-max-width: 430px;
  --header-height: 210px;
}

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  flex: auto;
  background-color:var(--bs-body-bg);
  text-align: center;
}

#root {
  display: flex;
  flex-direction: column;
  flex: auto;
}

#root > .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: auto;
}

#root > .container > form {
  max-width: var(--form-max-width);
  min-width: calc(320px - 2 * var(--bs-gutter-x));
  margin: 0px;
  display: flex;
  flex-direction: column;
  flex: auto;
  width: -webkit-fill-available;
}

.orange{
  color: var(--mindaro-orange);
}

.form-flex-spacer {
  flex: auto;
}

input, select.form-select, input.form-control {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  padding: 14px;
  width: 100%;
}
input.with-text {
  width: calc(100% - 24px);
}
.input-group > span.input-group-text {
  border-color: transparent;
  margin-left: 0px;
  padding-left: 4px;
  padding-right: 4px;
  width: 24px;
}
.form-floating {
  width: 100%;
}
.form-floating>label {
  color: #808080;
  text-align: left;
  white-space: nowrap;
}
.form-floating.with-text {
  width: calc(100% - 24px);
}
.form-floating>.react-select-container~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.react-select-container {
  text-align: left;
  width: 100%;
  color: #FFFDF7;
  outline: none;
  border: none;
  margin-bottom: 40px;
}

.react-select-container .react-select__control, .react-select-container .react-select__control:hover {
  border-color: transparent;
  background-color: #272626;
  color: #fff;

}
.react-select-container .react-select__control :focus{
  border:1px solid #fff;
}
.react-select__menu-list::-webkit-scrollbar {
  width: 10px;
  height: 0px;
  
}
.react-select__menu-list::-webkit-scrollbar-track {
  background: #353535;
}
.react-select__menu-list::-webkit-scrollbar-thumb {
  background: #FB772E;
  border-radius: 5px;
}
.react-select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #353535;
}

.input-group .react-select-container.is-invalid .react-select__value-container {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-floating .react-select__value-container.react-select__value-container--has-value {
  overflow: visible;
}
.form-floating .react-select__value-container.react-select__value-container--has-value .react-select__single-value {
  overflow: visible;
  transform: translateY(0.5rem);
}

.pac-container{
  padding: 10px;
  background: #0D0D0D;
  border-radius: 4px;
  box-shadow : -4px 6px 19px rgba(255, 253, 247, 0.11);
  

}
.pac-icon, .pac-matched, .pac-item-query{
  color: #fff;
  
}
.pac-item{
  padding: 10px 0;
  color: #fff;
}

.pac-item:hover{
  background-color: #272626;
  
}

.input-group {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  margin-bottom: var(--bs-gutter-y);
}

.input-group.check-row {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 4px;
}
.input-group.check-row.is-invalid, .input-group.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-label {
  font-size: large;
  font-weight: bold;
  margin-bottom: var(--bs-gutter-y);
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: var(--mindaro-theme-primary);
  border-color: var(--mindaro-theme-primary);
  outline-color: var(--mindaro-theme-primary);
  margin-bottom: var(--bs-gutter-y);
  color: var(--mindaro-theme-primary-text);
  width: 100%;
  height: 42px;
  font-weight: 500;
}

.btn-primary:disabled {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.alertify button.ajs-button {
  display: none;
}
.ajs-dialog, .ajs-footer, .ajs-header {
  background: #282727 !important;
  border-radius: 15px;
  color: #fff;
}

.ajs-dialog{
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.18) !important;
  max-width : 545px !important;
  max-height: 400px !important;
}
.alertify.ajs-closable .ajs-commands button.ajs-close {
  background: none;
  transform: scale(1.5);
}
.alertify.ajs-closable .ajs-commands button.ajs-close::before {
  content: "\f00d";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  top: 2px;
  right: 0px;
  color : #FFFDF7;
}
.ajs-dialog, button.ajs-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -appearance: none;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  width: 220px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: center;
}
.alertify .ajs-header {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 76px;
  text-align: center;
  margin-top: 60px;
  
  background: linear-gradient(284.3deg, #FFD812 17.89%, #FB772E 83.07%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
}
.alertify-notifier {
  color: white;
}

.alertify .ajs-body{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  text-align: center; 
}

.offcanvas form {
  padding-left: var(--bs-gutter-x);
  padding-right: var(--bs-gutter-x);
}

.shadow {
  -webkit-appearance: none;
  -moz-appearance: none;
  -appearance: none;
}

.left-align {
  text-align: left;
}

.gutter-bottom {
  margin-bottom: var(--bs-gutter-y);
}

.small-grey {
  color: var(--bs-gray);
  font-size: small;
  font-weight: normal;
  padding: 0px var(--bs-gutter-x) var(--bs-gutter-y) var(--bs-gutter-x);
}

.hr-text {
  opacity: 1;
  overflow: visible;
  margin-bottom: var(--bs-gutter-y);
  margin-top: 0px;
}

.hr-text:after {
  background-color: var(--bs-body-bg);
  content: attr(data-content);
  padding: 0px 10px;
  position: relative;
  top: -0.8em;
}

#SignUpWithGoogle {
  background-color: white;
  border: none;
  color: var(--bs-body-color);
}

#SignUpWithApple {
  background-color: black;
  border: none;
}
.input-hint {
  color: var(--bs-gray);
  font-size: small;
  text-align: left;
  margin-bottom: var(--bs-gutter-y);
}

.code-input {
  font-size: x-large;
  margin: 6px;
  padding: 0.2em;
  width: 1.3em;
}

.input-row {
  margin-bottom: var(--bs-gutter-y);
}

.form-check {
  text-align: left;
  white-space: nowrap;
}

#firstInsuranceGroup .form-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-check-label {
  text-align: left;
  white-space: normal;
}

.form-check-input:checked {
  background-color: var(--mindaro-blue);
  border-color: var(--mindaro-blue);
}

.divided-label {
  border-right: solid 1px black;
  text-align: left;
  padding: 14px;
  width: calc(100% - 99px);
}

.divided-edit.with-text {
  width: 75px;
}
.divided-edit.with-text input {
  text-align: right;
}

.divided-group {
  border-radius: 4px;
}

a{
  color: #FB772E;
  text-decoration: none;
}

a:hover{
  color : var(--mindaro-theme-secondary);
}

.quote-button, .quote-button:hover{
  width: 191px;
  border-radius: 15px;
}

.quote-button:hover {
  background-color: var(--mindaro-theme-third);
  border-color: var(--mindaro-theme-third);
  outline-color: var(--mindaro-theme-third);
}

.pink {
  color: var(--hover-color);
}
.orange{
  color : var(--mindaro-orange)
}
.green {
  color: var(--mindaro-green);
}

h1.woohoo {
  font-size: 50px;
}

form .row {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  width: 100%;
}

form .col-4, form .col-8 {
  margin: 0px;
  padding: 0px 3px 0px 3px;
}

input[type="radio"], input[type="checkbox"] {
  height: 24px;
  margin-right: 10px;
  margin-top: 0px;
  width: 24px;
}

.badge-close {
  cursor: pointer;
}

#total-row div {
  padding: 10px 27px;
  text-align: right;
}
#total-row.green {
  color: green;
}
#total-row.red {
  color: red;
}

.react-calendar {
  border: none;
  border-radius: 4px;
  font-family: inherit;
  padding: 8px;
  width: 100%;
}
.react-calendar__month-view__days__day--weekend {
  color: var(--hover-color);
}
.react-calendar__tile, .react-calendar__navigation__label {
  font-weight: 500;
}
.react-calendar__tile--now {
  background-color: var(--mindaro-yellow);
}
.react-calendar__tile--active {
  background-color: var(--mindaro-blue);
}
.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
  font-size: 32px;
  font-weight: 500;
}
.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
  display: none;
}

.progress-bar {
  background-color: var(--mindaro-blue);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labeled-switch {
  border-radius: 8px;
  margin-bottom: var(--bs-gutter-y);
  padding: 14px;
  text-align: left;
}
.labeled-switch > label {
  width: calc(100% - 58px);
}
.mindaro-switch {
  float: right;
}
.mindaro-switch .react-switch-bg > div {
  width: 100% !important;
}

#addressAutocompleteGroup > img {
  height: 23px;
  margin: auto 0px auto 10px;
  width: 20px;
}
#addressAutocomplete {
  margin-left: 4px;
  width: calc(100% - 34px);
}

.plus-minus {
  display: inline-block;
  font-size: 16px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  padding-top: 2px;
}

#buy-button {
  display: inline-block;
}

.green-check {
  color: var(--mindaro-green);
}

.preview-dialog {
  height: calc(100% - 3rem);
  margin: 1.75rem auto;
  max-width: 100%;
}
.preview-content {
  background-color: #282727;
  ;
  height: 100%;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.description{
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.pac-logo:after {
  display: none !important;
}
button.green-button.btn-primary:not(#buy-button) {
  width: 100%;
  height: 48px;
  margin:20px 0;
  border-radius: 15px; 
}

button.green-button.btn-primary:not(#buy-button):hover {
  background-color: var(--mindaro-theme-third);
  outline: none;
  border: none;
}
.modalStyles{
border-radius: 15px;
box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.18);
}

.form-check-input[type=checkbox]{
  background: #1B1D21;
  box-shadow: -4px -4px 10px rgba(255, 255, 255, 0.07), 4px 4px 10px rgba(0, 0, 0, 0.62);
  width:31px;
  height: 31px;
  border-radius : 50%;
  outline:none;
  border:none;
  background-image: url("./assets/unchecked.png");
  background-repeat : no-repeat;
  background-position: center;
  
}
.form-check-input:checked[type=checkbox] {
  background-image: url("./assets/checked.png");
  background-repeat : no-repeat;
  background-position: center;
  
}
.buttonwrapper{
  margin-top : 121px;
  margin-bottom : 190px;
  width: 100%;
}

.short-margin-buttonwrapper{
  margin-top: 45px;
  margin-bottom: 100px;
}

.standard-button {
  border-radius: 15px;
  
}

  @media(max-width : 766px) {
    .buttonwrapper{
        margin-top : 0;
        margin-bottom:0;
        position: relative;
        left:50%;
        bottom: 0px;
        transform: translateX(-50%);
        width: 100%;
        min-height: 15vh;

            
    }
    button.green-button.btn-primary:not(#buy-button) {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100%;
      /* height: 48px; */
      /* margin-bottom: 20px; */
      border-radius: 15px;
      text-align: center;
      margin-bottom: 1vh;
    }
  }

@media (min-width: 576px) {
  button.green-button.btn-primary:not(#buy-button) {
    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
    width: 180px;
    height: 48px;
    margin-bottom: 20px;
    border-radius: 15px;
    text-align: center;

  }
  .form-flex-spacer {
    flex: auto;
    max-height: 50px;
  }

  
  .preview-dialog {
    height: calc(100% - 3rem);
    margin: 1.75rem auto;
    max-width: calc(100% - var(--bs-gutter-x) * 2);
    }
}

