#footer-background {
  background-color: var(--mindaro-theme-primary-background);
  margin-top: var(--bs-gutter-y);
  padding-top: var(--bs-gutter-y);
  padding-bottom: var(--bs-gutter-y);
  color: var(--mindaro-theme-primary-text);
}
#footer-row {
  text-align: left;
}
#footer-row > * {
  margin-top: 19px;
}
#footer-row h6 {
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
}
#footer-row a {
  color: var(--mindaro-theme-primary-text);
  font-weight: 500, medium;
  font-size: 17px;
  line-height: 26px;
}
#social-row {
  margin-top: 35px;
  text-align: left;
}
#social-row .fab {
  font-size: x-large;
  margin-right: 20px;
  color: var(--mindaro-theme-primary-text);
}
#footer-row input {
  background-color: var(--bs-body-bg);
  width: 100%;
}

#footer-row img {
  height: 20px;
  float: right;
  margin-top: 20px;
}
#footer-row .fas.fa-info-circle {
  margin-left: 10px;
  top: -10px;
  position: relative;
}
#footer-row .form-flex-spacer {
  display: none;
}
#footer-row .form-floating {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: calc(100% - 125px);
  padding-right: 10px;
}
#vip-thanks {
  background-color: var(--mindaro-theme-primary-background);
  padding: 20px;
}

.footer-text {
  color: #848484;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: justify;
  margin: 42px 0;
}

.stay-uptodate-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  margin: 16px 0;
  text-align: start;
}

.follow-us {
  text-align: left;
  font-weight: 800;
  font-size: 17px;
  line-height: 26px;
}
.mindaro-email {
  margin-top: 20px;
}

.mindaro-logo {
  height: 20px;
  width: 180px;
  transform: scale(1.9);
}

.signup-input {
  color: var(--mindaro-theme-primary-text);
  border-bottom: 2px solid var(--mindaro-theme-primary-text);
  outline: none;
  padding-left: 0px;
}

.signup-input::placeholder {
  color: var(--mindaro-theme-primary-text);
}

.signup-section h6 {
  text-align: start;
  max-width: 500px;
}

.signup-section h6 {
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 26px;
}

hr {
  margin-top: 35px;
  border: 1px solid var(--mindaro-theme-primary-text);
}

@media (max-width: 576px) {
  #social-row {
    display: flex;
    flex-wrap: none;
    justify-content: space-between;
  }
  #social-row .fab {
    font-size: xx-large;
    margin-right: unset;
  }
  #footer-row .form-floating {
    width: 100%;
    margin-right: 0px;
    padding-right: 0px;
  }

  #footer-row .fas {
    margin-left: unset;
    top: unset;
    float: right;
    position: relative;
  }

  #footer-row a {
    color: var(--mindaro-theme-primary-text);
    font-weight: 500, medium;
    font-size: 20px;
    line-height: 32px;
  }
}

.mindaro-text {
  position: relative;
  width: 100%;
}

.mindaro-text > label {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #0a0909;
}

.mindaro-text.white > label {
  color: #f8f8f8 !important;
}

.mindaro-text > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 5px 0px 5px 0px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.mindaro-text > .mindaro-text-input:focus ~ label,
.mindaro-text > .mindaro-text-input:not(:placeholder-shown) ~ label,
.mindaro-text > .mindaro-select ~ label {
  top: -10px;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.mindaro-text > .mindaro-text-input {
  padding: 10px 0px 5px 0px;
  line-height: 1.25;
}

.mindaro-text.white > .mindaro-text-input {
  color: #f8f8f8 !important;
  border-bottom: 2px solid #f8f8f8 !important;
}

.mindaro-text-input {
  display: block;
  width: 100%;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
  background-color: transparent !important;
  background-clip: padding-box;
  border-width: 0px;
  border-bottom: 2px solid #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mindaro-text-input::placeholder {
  color: transparent;
}

.mindaro-text-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}
.mindaro-text-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}
.mindaro-text-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}
.mindaro-text-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}

.mindaro-text-input:focus,
.mindaro-text-input:focus-visible {
  outline: none;
}

.footer-quote-button,
.footer-quote-button:hover {
  width: 191px;
  border-radius: 15px;
}

.footer-quote-button:hover {
  background-color: var(--mindaro-theme-third);
  border-color: var(--mindaro-theme-third);
  outline-color: var(--mindaro-theme-third);
}

@media (max-width: 768px) {
  .quote-button-wrapper {
    padding-left: 0px;
  }
}
