.header-wrapper {
  background-color: var(--mindaro-theme-primary-background);
  position: sticky;
  top: 0;
  z-index: 10;
}

#mindaroRow {
  margin: 0px;
  padding-top: 22px;
  padding-bottom: 22px;
}

#mindaroRow > div:nth-of-type(1) {
  text-align: left;
  padding-left: 0px;
}

#mindaroRow > div:nth-last-of-type(1) {
  text-align: right;
  padding-right: 0px;
}

#mindaroBack {
  height: 26px;
  opacity: 0.5;
  width: 30px;
}

#mindaroBack::before {
  position: relative;
  top: 4px;
}

#mindaroBack:hover,
#mindaroBack:focus,
#mindaroBack:active {
  color: var(--mindaro-pink);
}

#mindaroImage {
  height: 30px;
  width: auto;
}

#mindaroRow .navbar,
#mindaroRow .navbar .container,
#mindaroRow .navbar .container button.navbar-toggler {
  padding: 0px;
}

#mindaroRow .navbar .container {
  justify-content: flex-end;
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: var(--mindaro-theme-primary);
  border-color: var(--mindaro-theme-primary);
  outline-color: var(--mindaro-theme-primary);
  margin-bottom: var(--bs-gutter-y);
  color: var(--mindaro-theme-primary-text);
  width: 100%;
  height: 42px;
  font-weight: 500;
  font-family: 'Manrope';
}

.btn-primary:disabled {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.quote-button, .quote-button:hover{
  width: 191px;
  border-radius: 15px;
}

.quote-button:hover {
  background-color: var(--mindaro-theme-third);
  border-color: var(--mindaro-theme-third);
  outline-color: var(--mindaro-theme-third);
}

#mindaroRow .navbar .container button.navbar-toggler {
  border: none;
}

#mindaroRow .navbar .container button.navbar-toggler .navbar-toggler-icon {
  background-image: none;
}

button.navbar-toggler,
button.navbar-toggler:focus {
  outline: none;
}

.text-mindaro-third, .text-mindaro-third:hover {
  color: var(--mindaro-theme-third);
  font-family: 'Manrope';
}

#offcanvasNavbar {
  background-color: var(--mindaro-theme-primary-background);
  box-shadow: -2px 0px 44px rgba(248, 248, 248, 0.18);
  overflow-y: auto;
  width: 332px;
  max-width: 80%;
}

#offcanvasNavbar .nav-link {
  color: var(--mindaro-theme-primary-text);
  font-weight: 300;
  padding: 14px 35px;
  text-align: left;
  transition-duration: 0s;
  border-left: 5px solid transparent;
  font-family: 'Manrope';
}

#offcanvasNavbar .nav-link.active, #offcanvasNavbar .nav-link:hover {
  border-left: 5px solid var(--mindaro-theme-third);
  color: var(--mindaro-theme-third);
  font-weight: 800;
}

.offcanvas-header {
  padding-left: 35px;
  padding-right: 35px;
  justify-content: end;
}

.offcanvas-body {
  overflow-y: initial;
  padding-left: 0px;
  padding-right: 0px;
}

.toggle-icon {
  color: #F8F8F8;
  cursor: pointer;
  width: 47px;
  height: 28px;
}

.toggle-icon:hover {
  color: var(--mindaro-theme-third);
}

#headerLoginButtons {
  display: block;
}

@media (max-width:576px){
  .mobileHeader{
    padding:0;
  }

  #mindaroRow {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #mindaroImage {
    height: 24px;
  }

  .toggle-icon {
    width: 22px;
    height: 16px;
  }

  .quote-button, .quote-button:hover{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 35px;
    border-radius: 15px;
    font-weight:900;
    font-size: 12px;
    line-height: 10px;
  }
}

@media (max-width:376px){
  #mindaroImage {
    height: 20px;
    margin-top: -9px;
  }

  .quote-button, .quote-button:hover{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 25px;
    border-radius: 6px;
    font-weight:900;
    font-size: 12px;
    line-height: 10px;
    padding: 8px;
  }
}